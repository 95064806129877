import React, { Suspense, lazy } from "react";
// noinspection ES6CheckImport
import { BrowserRouter as Router, Route } from "react-router-dom";
const Loading = lazy(() =>
  import(
    /* webpackChunkName: "Loading" */ /* webpackPrefetch: true */ "./Loading"
  )
);
const ProtectedRoute = lazy(() =>
  import(
    /* webpackChunkName: "ProtectedRoute" */ /* webpackPrefetch: true */ "./sitio-web/components/ProtectedRoute"
  )
);
const HomeWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "HomeWebContainer" */ /* webpackPreload: true */ "./sitio-web/containers/home/HomeWebContainer"
  )
);
const ContactWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ContactWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/contact/ContactWebContainer"
  )
);
const BlogWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "BlogWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/blog/BlogWebContainer"
  )
);
const ProductsWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ProductsWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/products/ProductsWebContainer"
  )
);
const ArticleWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ArticleWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/blog/ArticleWebContainer"
  )
);
const HistoryWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "HistoryWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/about-us/HistoryWebContainer"
  )
);
const AboutWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "AboutWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/about-us/AboutWebContainer"
  )
);
const ProductWebContainer = lazy(() =>
  import(
    /* webpackChunkName: "ProductWebContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/products/ProductWebContainer"
  )
);
const CartContainer = lazy(() =>
  import(
    /* webpackChunkName: "CartContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/CartContainer"
  )
);
const CheckoutContainer = lazy(() =>
  import(
    /* webpackChunkName: "CheckoutContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/CheckoutContainer"
  )
);
const ClientLoginContainer = lazy(() =>
  import(
    /* webpackChunkName: "ClientLoginContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/client_area/ClientLoginContainer"
  )
);
const ClientOrdersContainer = lazy(() =>
  import(
    /* webpackChunkName: "ClientOrdersContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/client_area/ClientOrdersContainer"
  )
);
const PrivacyPolicyContainer = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/PrivacyPolicyContainer"
  )
);
const OxxoContainer = lazy(() =>
  import(
    /* webpackChunkName: "OxxoContainer" */ /* webpackPrefetch: true */ "./sitio-web/containers/cart/OXXOContainer"
  )
);

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Route exact path="/" component={HomeWebContainer} />
        <Route exact path="/quienes-somos" component={AboutWebContainer} />
        <Route exact path="/historia" component={HistoryWebContainer} />
        <Route
          exact
          path="/nuestros-productos"
          component={ProductsWebContainer}
        />
        <Route exact path="/producto/:name" component={ProductWebContainer} />
        <Route exact path="/blog" component={BlogWebContainer} />
        <Route exact path="/articulo/:name" component={ArticleWebContainer} />
        <Route exact path="/contacto" component={ContactWebContainer} />
        <Route exact path="/carrito" component={CartContainer} />
        <Route exact path="/finalizar-compra" component={CheckoutContainer} />
        <Route exact path="/recibo-oxxo" component={OxxoContainer} />
        <Route
          exact
          path="/politica-privacidad"
          component={PrivacyPolicyContainer}
        />
        <Route exact path="/iniciar-sesion" component={ClientLoginContainer} />
        <ProtectedRoute
          exact
          path="/mis-pedidos"
          component={ClientOrdersContainer}
        />
      </Router>
    </Suspense>
  );
}

export default App;
